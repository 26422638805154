import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/builds/frontend/chameleon-docs/src/components/Layout/DefaultLayout.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`Use a tooltip to help users understand the meaning of purpose of UI elements
that aren't described directly like icons without labels.`}</p>
    <h3 {...{
      "id": "positioning",
      "style": {
        "position": "relative"
      }
    }}>{`Positioning`}</h3>
    <p>{`Tooltips can be positioned on both top or the bottom of a trigger and change
left or right depending on how close the element is to the edge of the screen.`}</p>
    <iframe style={{
      "border": "1px solid rgba(0, 0, 0, 0.1)"
    }} width="100%" height="450" src="https://www.figma.com/embed?embed_host=share&url=https%3A%2F%2Fwww.figma.com%2Ffile%2FeVuNlIBZh5wbDiM4blDaEb%2FDocumentation-Embeds%3Fnode-id%3D137%253A0&chrome=DOCUMENTATION" allowFullScreen></iframe>
    <h3 {...{
      "id": "behaviour",
      "style": {
        "position": "relative"
      }
    }}>{`Behaviour`}</h3>
    <p>{`A tooltip is triggered or dismissed by:`}</p>
    <ul>
      <li parentName="ul">{`Hovering over an element`}</li>
      <li parentName="ul">{`Clicking or tapping`}</li>
      <li parentName="ul">{`Focusing on an element`}</li>
    </ul>
    <p>{`When triggered, the tooltip will animate on entrance and exit.`}</p>
    <iframe style={{
      "border": "1px solid rgba(0, 0, 0, 0.1)"
    }} width="100%" height="450" src="https://www.figma.com/embed?embed_host=share&url=https%3A%2F%2Fwww.figma.com%2Fproto%2FeVuNlIBZh5wbDiM4blDaEb%2FDocumentation-Embeds%3Fnode-id%3D140%253A3%26viewport%3D-52%252C-454%252C1.785691499710083%26scaling%3Dcontain&chrome=DOCUMENTATION" allowFullScreen></iframe>
    <h3 {...{
      "id": "guidelines",
      "style": {
        "position": "relative"
      }
    }}>{`Guidelines`}</h3>
    <ul>
      <li parentName="ul">{`Tooltips should be concise, helpful and supplemental.`}</li>
      <li parentName="ul">{`Tooltips have a max-width of 230px.`}</li>
      <li parentName="ul">{`Tooltips should be written in sentence case.`}</li>
      <li parentName="ul">{`Tooltips should contain only static content.`}</li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      